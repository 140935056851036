import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const themeApi = createApi({
  reducerPath: 'themeApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['ThemeTag'],
  endpoints: (builder) => ({
    getThemes: builder.query({
      query: (subjectId) => `theme?subjectId=${subjectId}`,

      providesTags: ['ThemeTag'],
    }),

    addTheme: builder.mutation({
      query: (body) => ({
        url: `theme`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ThemeTag'],
    }),

    updateTheme: builder.mutation({
      query: ({ id, body }) => ({
        url: `theme/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ThemeTag'],
    }),

    deleteTheme: builder.mutation({
      query: (id) => ({
        url: `theme/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ThemeTag'],
    }),
  }),
});

export const { useAddThemeMutation, useDeleteThemeMutation, useGetThemesQuery, useUpdateThemeMutation } = themeApi;

import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import { useGetSubjectsQuery } from '../redux/api/subjectApi';

// ----------------------------------------------------------------------

const initialState = {
  subject: null,
  subjects: [],
  setSubject: () => {},
};

const SubjectContext = createContext(initialState);

// ----------------------------------------------------------------------

SubjectProvider.propTypes = {
  children: PropTypes.node,
};

function SubjectProvider({ children }) {
  const { data, isLoading, isFetching } = useGetSubjectsQuery();

  const [subject, setSubject] = useState();

  return (
    <SubjectContext.Provider
      value={{
        subjects: data,
        subject,
        setSubject,
      }}
    >
      {children}
    </SubjectContext.Provider>
  );
}

export { SubjectContext, SubjectProvider };

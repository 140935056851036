import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

// slice
import categoryReducer from './slices/category';
import chatReducer from './slices/chat';
import extraReducer from './slices/extra';
import userReducer from './slices/user';

// api

import { analyticApi } from './api/analyticApi';
import { authApi } from './api/authApi';
import { categoryApi } from './api/categoryApi';
import { exerciseApi } from './api/exerciseApi';
import { productApi } from './api/productApi';
import { subjectApi } from './api/subjectApi';
import { themeApi } from './api/themeApi';
import { userApi } from './api/userApi';
import { levelApi } from './api/levelApi';
import { cuentaApi } from './api/cuentaApi';

// ----------------------------------------------------------------------
const rootReducer = combineReducers({
  chat: chatReducer,
  extra: extraReducer,
  category: categoryReducer,
  user: userReducer,

  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [analyticApi.reducerPath]: analyticApi.reducer,
  [subjectApi.reducerPath]: subjectApi.reducer,
  [exerciseApi.reducerPath]: exerciseApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [themeApi.reducerPath]: themeApi.reducer,
  [levelApi.reducerPath]: levelApi.reducer,
  [cuentaApi.reducerPath]: cuentaApi.reducer,
});

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(userApi.middleware)
      .concat(authApi.middleware)
      .concat(analyticApi.middleware)
      .concat(subjectApi.middleware)
      .concat(exerciseApi.middleware)
      .concat(productApi.middleware)
      .concat(themeApi.middleware)
      .concat(levelApi.middleware)
      .concat(cuentaApi.middleware)
      .concat(categoryApi.middleware),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

setupListeners(store.dispatch);

export { dispatch, persistor, store, useDispatch, useSelector };

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const exerciseApi = createApi({
  reducerPath: 'exerciseApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['ExerciseTag'],
  endpoints: (builder) => ({
    getExercises: builder.query({
      query: (subjectId) => `exercise?subjectId=${subjectId}`,
      providesTags: ['ExerciseTag'],
    }),
    getExercise: builder.query({
      query: (id) => `exercise/${id}`,
      providesTags: ['ExerciseTag'],
    }),

    addExercise: builder.mutation({
      query: (body) => ({
        url: `exercise`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ExerciseTag'],
    }),
    updateExercise: builder.mutation({
      query: ({ id, body }) => ({
        url: `exercise/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ExerciseTag'],
    }),
    deleteExercise: builder.mutation({
      query: (id) => ({
        url: `exercise/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ExerciseTag'],
    }),
  }),
});

export const { useAddExerciseMutation, useDeleteExerciseMutation, useGetExercisesQuery, useUpdateExerciseMutation, useLazyGetExerciseQuery } =
  exerciseApi;

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { logo } from '../assets';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {


  return (
    <RouterLink to="/">
      <Box sx={{ width: '70%', height: '60%', ...sx }}>
        <img src={logo} alt="logo" />
      </Box>
    </RouterLink>
  );
}

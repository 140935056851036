import { createSlice } from '@reduxjs/toolkit';

//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  category: null,
  activityTypes: [],
  isOpenModal: false,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    //  LOADING
    loading(state, action) {
      state.isLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET Types
    getTypesSuccess(state, action) {
      state.isLoading = false;
      state.activityTypes = action.payload;
    },

    // CREATE
    createCategorySuccess(state, action) {
      const newCategory = action.payload;
      state.isLoading = false;
      state.categories = [...state.categories, newCategory];
    },

    // UPDATE
    updateCategorySuccess(state, action) {
      const category = action.payload;
      const updateCategory = state.categories.map((_category) => {
        if (_category.id === category.id) {
          return category;
        }
        return _category;
      });

      state.isLoading = false;
      state.categories = updateCategory;
    },

    // DELETE
    deleteCategorySuccess(state, action) {
      const { id } = action.payload;
      const deleteCategory = state.categories.filter((category) => category.id !== id);
      state.categories = deleteCategory;
    },

    // SELECT
    selectCategory(state, action) {
      const category = action.payload;
      state.category = category;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openViewModal, openModal, closeViewModal, closeModal, selectCategory } = slice.actions;


import { useContext } from 'react';
//
import { SubjectContext } from '../contexts/SubjectContext';

// ----------------------------------------------------------------------

const useSubject = () => {
  const context = useContext(SubjectContext);

  if (!context) throw new Error('Subject context must be use inside SubjectProvider');

  return context;
};

export default useSubject;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const analyticApi = createApi({
  reducerPath: 'analyticApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['AnalyticTag'],
  endpoints: (builder) => ({
    getAnalytics: builder.query({
      query: () => `analytic`,
      providesTags: ['AnalyticTag'],
    }),
  }),
});

export const { useGetAnalyticsQuery } = analyticApi;

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

export default function ScreenLoader() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1000,
        opacity: 0.8,
      }}
    >
      <div
        style={{
          w: 200,
          paddingTop: 100,
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
          <CircularProgress size={60} style={{ color: 'white' }} />
        </Stack>
        <h1 style={{ fontSize: 40, color: 'white', marginTop: 4 }}>Cargando...</h1>
      </div>
    </div>
  );
}

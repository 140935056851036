/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Icon } from '@iconify/react/dist/iconify';
import { Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import useSubject from '../../../hooks/useSubject';
import { ArrowIcon } from './NavItem';

NavSubjectListRoot.propTypes = {
  isCollapse: PropTypes.bool,
};

export function NavSubjectListRoot({ isCollapse }) {
  const { subjects, setSubject, subject } = useSubject();

  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  if (isCollapse) return null;

  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div>
      {subject?._id && open ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            padding: 10,
            gap: 10,
            borderRadius: 5,
            marginBottom: 10,
            backgroundColor: alpha(theme.palette.grey[500], theme.palette.action.selectedOpacity),
            color: theme.palette.primary.dark,
          }}
          onClick={(_e) => {
            _e.preventDefault();
            setSubject(null);
            setOpen(false);
            navigate('/dashboard');
          }}
        >
          <Icon icon="weui:back-filled" />
          <Typography sx={{ textTransform: 'capitalize' }}>Volver al panel de control</Typography>
        </div>
      ) : null}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          padding: 10,
          borderRadius: 5,
          backgroundColor: alpha(theme.palette.grey[500], theme.palette.action.selectedOpacity),
          color: theme.palette.primary.main,
          marginBottom: open ? 10 : 0,
        }}
        onClick={(_e) => {
          _e.preventDefault();
          setOpen(!open);
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>Asignaturas</Typography>
        <ArrowIcon open={open} />
      </div>

      {open
        ? (subjects || []).map((_subject) => (
            <div
              key={_subject?._id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                padding: 10,
                borderRadius: 5,
                marginBottom: 10,
                backgroundColor: alpha(theme.palette.grey[500], theme.palette.action.selectedOpacity),
                color: theme.palette.grey.A700,
                borderBottomWidth:_subject?._id === subject?._id ? 2 : 0,
                borderBottomColor: 'black',
                borderBottomStyle: 'solid', // This line is necessary to render the border
              }}
              onClick={(_e) => {
                _e.preventDefault();
                setSubject(_subject);
                navigate('/theme');
              }}
            >
              <Typography sx={{ textTransform: 'capitalize' }}>{`${_subject?.title || ''}`}</Typography>
              {_subject?._id === subject?._id && <Icon icon="icon-park-outline:check-one" />}
            </div>
          ))
        : null}
    </div>
  );
}

import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { useLazyGetCuentasQuery } from '../redux/api/cuentaApi';

// ----------------------------------------------------------------------

const initialState = {
  cuentas: [],
  setCuentas: () => {},
};

const CuentaContext = createContext(initialState);

// ----------------------------------------------------------------------

CuentaProvider.propTypes = {
  children: PropTypes.node,
};

function CuentaProvider({ children }) {
  const [cuentas, setCuentas] = useLocalStorage('cuentas', []);

  const [getCuentas] = useLazyGetCuentasQuery();

  useEffect(() => {
    getCuentas()
      .unwrap()
      .then((data) =>
        setCuentas(
          (data || []).map((item) => ({
            value: item._id,
            label: item.text,
          }))
        )
      );
  }, []);

  const handleUpdateList = (list) => {
    setCuentas(
      (list || []).map((item) => ({
        value: item._id,
        label: item.text,
      }))
    );
  };

  return (
    <CuentaContext.Provider
      value={{
        cuentas,
        setCuentas: handleUpdateList,
      }}
    >
      {children}
    </CuentaContext.Provider>
  );
}

export { CuentaContext, CuentaProvider };

import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';

// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { AuthGuard, GuestGuard } from '../guards';
import SubjectUsers from '../pages/dashboard/SubjectUsers';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'users', element: <Users /> },
        { path: 'subject', element: <Subject /> },
        { path: 'subject-users', element: <SubjectUsers /> },
        { path: 'theme', element: <Theme /> },
        { path: 'products', element: <Products /> },
        { path: 'levels', element: <Level /> },
        { path: 'exercise', element: <Exercise /> },
        { path: 'exercise-result', element: <Exercise /> },
        { path: 'cuenta', element: <Cuenta /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const Users = Loadable(lazy(() => import('../pages/dashboard/Users')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const Subject = Loadable(lazy(() => import('../pages/dashboard/Subject')));
const Theme = Loadable(lazy(() => import('../pages/dashboard/Theme')));
const Exercise = Loadable(lazy(() => import('../pages/dashboard/Exercise')));
const Products = Loadable(lazy(() => import('../pages/dashboard/Products')));
const Level = Loadable(lazy(() => import('../pages/dashboard/Level')));
const Cuenta = Loadable(lazy(() => import('../pages/dashboard/Cuenta')));

// Error
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

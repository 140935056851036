import React, { useMemo } from 'react';
import Select from 'react-select';

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid #60a5fa' : '1px solid #d1d5db',
    boxShadow: state.isFocused ? '0 0 0 1px #60a5fa' : 'none',
    borderRadius: 7,
  }),
  menu: (base) => ({
    ...base,
    marginTop: 5,
    borderRadius: '0.5rem',
    border: '1px solid #d1d5db',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
  }),
  option: (base, state) => ({
    ...base,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: state.isSelected ? '#60a5fa' : state.isFocused ? '#bfdbfe' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#bfdbfe',
    },
  }),
};

const SearchableSelect = ({ value, options, onChange, ...others }) => {
  const option = useMemo(() => options.find(opt => opt.value === value), [value])

  return (
    <div className="w-full max-w-xs mx-auto mb-3">
      <Select options={options} styles={customStyles} value={option} onChange={(v) => onChange(v.value)} {...others} />
    </div>
  );
};

export default SearchableSelect;

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = (subject) =>
  subject
    ? [
        {
          subheader: '',
          items: [
            { title: 'Temas', path: PATH_DASHBOARD.general.theme, icon: ICONS.user },
            { title: 'Ejercicios', path: PATH_DASHBOARD.general.exercise, icon: ICONS.user },
            { title: 'Usuarios', path: PATH_DASHBOARD.general.subjectUsers, icon: ICONS.user },
          ],
        },
      ]
    : [
        {
          subheader: '',
          items: [
            { title: 'Panel de control', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.user },
            { title: 'Usuarios', path: PATH_DASHBOARD.general.users, icon: ICONS.user },
            { title: 'Productos', path: PATH_DASHBOARD.general.products, icon: ICONS.user },
            { title: 'Rangos', path: PATH_DASHBOARD.general.levels, icon: ICONS.user },
            { title: 'Cuentas', path: PATH_DASHBOARD.general.cuenta, icon: ICONS.user },
          ],
        },
      ];

export default navConfig;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const cuentaApi = createApi({
  reducerPath: 'cuentaApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['CuentaTag'],
  endpoints: (builder) => ({
    getCuentas: builder.query({
      query: () => `cuenta`,
      providesTags: ['CuentaTag'],
    }),

    addCuenta: builder.mutation({
      query: (body) => ({
        url: `cuenta`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CuentaTag'],
    }),

    updateCuenta: builder.mutation({
      query: ({ id, body }) => ({
        url: `cuenta/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CuentaTag'],
    }),

    deleteCuenta: builder.mutation({
      query: (id) => ({
        url: `cuenta/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CuentaTag'],
    }),
  }),
});

export const { useAddCuentaMutation, useDeleteCuentaMutation, useGetCuentasQuery, useUpdateCuentaMutation, useLazyGetCuentasQuery } = cuentaApi;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const levelApi = createApi({
  reducerPath: 'levelApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['LevelTag'],
  endpoints: (builder) => ({
    getLevels: builder.query({
      query: () => `level`,
      providesTags: ['LevelTag'],
    }),

    addLevel: builder.mutation({
      query: (body) => ({
        url: `level`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LevelTag'],
    }),

    updateLevel: builder.mutation({
      query: ({ id, body }) => ({
        url: `level/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['LevelTag'],
    }),

    deleteLevel: builder.mutation({
      query: (id) => ({
        url: `level/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LevelTag'],
    }),
  }),
});

export const { useAddLevelMutation, useDeleteLevelMutation, useGetLevelsQuery, useUpdateLevelMutation } = levelApi;

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import dayjs, { extend } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import { useSnackbar } from 'notistack';
import Iconify from '../../../components/Iconify';
import { useDeleteUserMutation } from '../../../redux/api/userApi';
import './DataTableDemo.css';
import './style.css';

extend(relativeTime)

const Table = ({ items = [], onRowClick, title = '' }) => {
  const [deleteUser] = useDeleteUserMutation();
  const { enqueueSnackbar } = useSnackbar();

  const tableRef = useRef(null);

  const [selectedItems, setSelectedItems] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    points: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportCSV = (selectionOnly) => {
    tableRef.current.exportCSV({ selectionOnly });
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(selectedItems?.length ? selectedItems : items);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'Usuarios');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
      }
    });
  };

  const renderHeader = () => (
    <div className="flex justify-content-between align-items-center">
      <h5 className="m-0">{title}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Búsqueda de palabras clave" />

        <Button
          type="button"
          icon="pi pi-file"
          onClick={() => exportCSV(false)}
          className="mx-2"
          data-pr-tooltip="CSV"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success mr-2"
          data-pr-tooltip="XLS"
        />
      </span>
    </div>
  );

  const actionBodyTemplate = (row) => (
    <Button
      type="button"
      className="p-button-danger"
      icon={
        <Iconify
          icon={'eva:trash-2-outline'}
          sx={{
            ...{
              mr: 2,
              width: 20,
              height: 20,
            },
            padding: 0,
            margin: 0,
            color: 'error.main',
          }}
        />
      }
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await deleteUser(row?._id).unwrap();
        enqueueSnackbar('cambios guardados exitosamente!');
      }}
      style={{ backgroundColor: 'white' }}
    />
  );

  const header = renderHeader();

  return (
    <div className="datatable-doc-demo">
      <div className="card">
        <DataTable
          ref={tableRef}
          value={items}
          paginator
          className="p-datatable-customer"
          onRowClick={({ data }) => onRowClick(data)}
          header={header}
          rows={5}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[5, 10, 25, 50]}
          dataKey="id"
          rowClassName="cursor-pointer"
          selectionMode="checkbox"
          selection={selectedItems}
          onSelectionChange={(e) => setSelectedItems(e.value)}
          filters={filters}
          filterDisplay="menu"
          responsiveLayout="scroll"
          globalFilterFields={['name', 'email', 'phoneNumber']}
          emptyMessage="No se encontraron artículos."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column selectionMode="multiple" selectionAriaLabel="name" headerStyle={{ width: '3em' }} />
          <Column field="name" header="Nombre" sortable filter filterPlaceholder="Search by Nombre" />
          <Column field="points" header="Puntos" sortable />
          <Column field="biscuits" header="Galletas" sortable />
          <Column
            field="isEmailVerified"
            header="Mail Verificar"
            sortable
            body={(row) => (row.isEmailVerified ? 'Yes' : 'No')}
          />
          <Column field="createdAt" header="Añadir" sortable body={(row) => dayjs(row.createdAt).fromNow()} />
          <Column field="email" header="Mail" sortable filter filterPlaceholder="Search by Mail" />
          <Column
            header="Acción"
            headerStyle={{ width: '2rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default Table;

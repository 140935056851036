import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['productTag'],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => `product`,
      providesTags: ['productTag'],
    }),

    addProduct: builder.mutation({
      query: (body) => ({
        url: `product`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['productTag'],
    }),

    updateProduct: builder.mutation({
      query: ({ id, body }) => ({
        url: `product/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['productTag'],
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `product/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['productTag'],
    }),
  }),
});

export const { useAddProductMutation, useDeleteProductMutation, useGetProductsQuery, useUpdateProductMutation } =
  productApi;

/* eslint-disable no-unreachable */
import axios from 'axios';
import { ACCESS_TOKEN_NAME, BACKEND_API, CLOUDFRONT_URL } from '../config';

export const uploader = async (file, path = '') => {
  try {

    if (typeof file === 'string') return { getUrl: file };
    const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const { data } = await axios.post(
      `${BACKEND_API}upload`,
      {
        path,
        contentType: file.type || 'image/*',
        fileFormat: `${file.type}`.split('/').pop(),
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    await axios.put(data.putUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return {
      getUrl: `${CLOUDFRONT_URL}/${data?.path}`,
      path: data.path,
    };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import ScreenLoader from './ScreenLoader';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, isLoading, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Minimal-UI`}</title>
      {meta}
    </Helmet>
    
    {isLoading ? <ScreenLoader /> : null}

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default Page;

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, IconButton, InputAdornment, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Iconify from '../../components/Iconify';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../components/hook-form';
import useAuth from '../../hooks/useAuth';
import { useAddUserMutation, useUpdateUserMutation } from '../../redux/api/userApi';
import { uploader } from '../../utils/uploader';

// ----------------------------------------------------------------------

const FormSchema = (isCreating) =>
  Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    email: Yup.string().max(255).required('Email is required'),
    image: Yup.mixed().required('Image is required'),
    password: isCreating ? Yup.string().required('Password is required') : Yup.string(),
  });

// ----------------------------------------------------------------------

const getInitialValues = (user) => ({
  name: user?.name || '',
  email: user.email || '',
  image: user.image || null,
});

// ----------------------------------------------------------------------

Form.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
};

export default function Form({ item, onCancel }) {
  const [addUser] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const [showPassword, setShowPassword] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const isCreating = !item._id;

  const methods = useForm({
    resolver: yupResolver(FormSchema(isCreating)),
    defaultValues: { ...getInitialValues(item) },
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    try {
      const body = { ...values};
      if (body?.image) body.image = (await uploader(body?.image, 'user-image')).getUrl;

      if (isCreating) {
        await addUser(body).unwrap();
        enqueueSnackbar('cambios guardados exitosamente!');
      } else {
        await updateUser({
          body,
          id: item._id,
        }).unwrap();
        enqueueSnackbar('cambios guardados exitosamente!');
      }
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1} sx={{ p: 3 }}>
        <RHFUploadAvatar name="image" accept="image/*" onDrop={handleDrop} />
        <RHFTextField name="name" label="Nombre" />
        <RHFTextField name="email" label="Mail" />

        {isCreating ? (
          <RHFTextField
            name="password"
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : null}
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancelar
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting} loadingIndicator="Cargando...">
          {isCreating ? 'Añadir' : 'Actualizar'}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTKBaseQuery } from '../../config';

export const subjectApi = createApi({
  reducerPath: 'subjectApi',
  baseQuery: fetchBaseQuery(RTKBaseQuery),
  tagTypes: ['SubjectTag'],
  endpoints: (builder) => ({
    getSubjects: builder.query({
      query: () => `subject`,
      providesTags: ['SubjectTag'],
    }),
    getSubjectUsers: builder.query({
      query: (subjectId) => `subject/users?subjectId=${subjectId}`,
      providesTags: ['SubjectTag'],
    }),
    getSubjectWithThemes: builder.query({
      query: () => `subject/themes`,
      providesTags: ['SubjectTag'],
    }),

    addSubject: builder.mutation({
      query: (body) => ({
        url: `subject`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SubjectTag'],
    }),

    updateSubject: builder.mutation({
      query: ({ id, body }) => ({
        url: `subject/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SubjectTag'],
    }),

    deleteSubject: builder.mutation({
      query: (id) => ({
        url: `subject/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SubjectTag'],
    }),
  }),
});

export const {
  useAddSubjectMutation,
  useDeleteSubjectMutation,
  useGetSubjectsQuery,
  useUpdateSubjectMutation,
  useGetSubjectWithThemesQuery,
  useGetSubjectUsersQuery
} = subjectApi;

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, 'dashboard'),
    pharmacies: path(ROOTS_DASHBOARD, 'pharmacies'),
    orders: path(ROOTS_DASHBOARD, 'orders'),
    users: path(ROOTS_DASHBOARD, 'users'),
    subjectUsers: path(ROOTS_DASHBOARD, 'subject-users'),
    pharmacyMedicine: path(ROOTS_DASHBOARD, 'pharmacy-medicine'),
    offer: path(ROOTS_DASHBOARD, 'offer'),
    article: path(ROOTS_DASHBOARD, 'article'),
    homePage: path(ROOTS_DASHBOARD, 'home-page'),
    subject: path(ROOTS_DASHBOARD, 'subject'),
    theme: path(ROOTS_DASHBOARD, 'theme'),
    exercise: path(ROOTS_DASHBOARD, 'exercise'),
    products: path(ROOTS_DASHBOARD, 'products'),
    levels: path(ROOTS_DASHBOARD, 'levels'),
    exerciseResults: path(ROOTS_DASHBOARD, 'exercise-result'),
    cuenta: path(ROOTS_DASHBOARD, 'cuenta'),
  },

  chat: {
    root: path(ROOTS_DASHBOARD, 'chat'),
    new: path(ROOTS_DASHBOARD, 'chat/new'),
    conversation: path(ROOTS_DASHBOARD, 'chat/:conversationKey'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
// import Editor from '../editor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

// ----------------------------------------------------------------------

RHFCKEditor.propTypes = {
  name: PropTypes.string,
};

export default function RHFCKEditor({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CKEditor
          {...field}
          id={name}
          editor={ClassicEditor}
          data={field.value}
          config={{
            toolbar: [
              '|',
              'bold',
              'italic',
              'undo',
              'redo',
            ],
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            field.onChange(data);
          }}
          error={!!error}
          helperText={
            <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
              {error?.message}
            </FormHelperText>
          }
          {...other}
        />
      )}
    />
  );
}
